<!-- <p>wall works!</p> -->
<div class="designView active">
    <div class="DSA_mainwrapper">
        <app-header *ngIf="userDetailsData" [roles]="roles" [entities]="entities" [entityRoles]="entityRoles"
            [roleAndFeatures]="roleAndFeatures" (homeEvent)="homeEvent($event)" [links]="links"
            [userDetailsData]="userDetailsData" (maintenanceMode)="checkMaintenanceMode($event)"></app-header>
            <ng-container *ngIf="!maintenanceEnabled">
                <div class="DSA_baseLayout_center padT" style="margin-bottom: 20px;">
                    <router-outlet></router-outlet>
                </div>
            </ng-container>
    </div>
</div>