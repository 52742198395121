import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
// import { DynamicFieldComponent } from './dynamicUI/dynamic-field/dynamic-field.component';
// import { DynamicFormComponent } from './dynamicUI/dynamic-form/dynamic-form.component';
// import { InputComponent } from './dynamicUI/controls/input/input.component';
// import { SelectComponent } from './dynamicUI/controls/select/select.component';
// import { ButtonComponent } from './dynamicUI/controls/button/button.component';
// import { DateComponent } from './dynamicUI/controls/date/date.component';
// import { RadiobuttonComponent } from './dynamicUI/controls/radiobutton/radiobutton.component';
// import { TitleComponent } from './dynamicUI/controls/title/title.component';
// import { TextareaComponent } from './dynamicUI/controls/textarea/textarea.component';
// import { ChipComponent } from './dynamicUI/controls/chip/chip.component';
// import { DynamicExpansionPanelComponent } from './dynamicUI/dynamic-expansion-panel/dynamic-expansion-panel.component';
// import { AutocompleteComponent } from './dynamicUI/controls/autocomplete/autocomplete.component';
// import { ChipfullwidthComponent } from './dynamicUI/controls/chipfullwidth/chipfullwidth.component';
// import { LabelComponent } from './dynamicUI/controls/label/label.component';
// import { AttachmentComponent } from './components/attachment/attachment.component';
// import { AttachmentDialogComponent } from './components/attachment-dialog/attachment-dialog.component';
// import { DragDirective } from './components/attachment-dialog/drag-drop.directive';
// import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
// import { PhonenumberComponent } from './dynamicUI/controls/phonenumber/phonenumber.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
// import { PrivacyConsentComponent } from './components/privacy-consent/privacy-consent.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { InitialsPipe } from './pipes/initials.pipe';
import { WallComponent } from './components/wall/wall.component';
import { RouterModule } from '@angular/router';
import { NewFooterComponent } from './components/new-footer/new-footer.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { DynamicFormComponent } from './components/dynamicUI/dynamic-form/dynamic-form.component';
import { DynamicFieldComponent } from './components/dynamicUI/dynamic-field/dynamic-field.component';
import { InputComponent } from './components/dynamicUI/controls/input/input.component';
import { SelectComponent } from './components/dynamicUI/controls/select/select.component';
import { ButtonComponent } from './components/dynamicUI/controls/button/button.component';
import { DateComponent } from './components/dynamicUI/controls/date/date.component';
import { PhonenumberComponent } from './components/dynamicUI/controls/phonenumber/phonenumber.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { AutocompleteComponent } from './components/dynamicUI/controls/autocomplete/autocomplete.component';
import { ChipfullwidthComponent } from './components/dynamicUI/controls/chipfullwidth/chipfullwidth.component';
import { LabelComponent } from './components/dynamicUI/controls/label/label.component';
import { AttachmentDialogComponent } from './components/attachment-dialog/attachment-dialog.component';
import { DragDirective } from './components/attachment-dialog/drag-drop.directive';
import { RadiobuttonComponent } from './components/dynamicUI/controls/radiobutton/radiobutton.component';
import { CheckboxComponent } from './components/dynamicUI/controls/checkbox/checkbox.component';
import { AttachmentDragDropComponent } from './components/dynamicUI/controls/attachment-DragDrop/attachment-DragDrop.component';
import { AttachmentComponent1 } from './components/dynamicUI/controls/attachment/attachment.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ValidatorComponent } from './components/validator/validator.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UnauthComponent } from './components/unauth/unauth.component';
import { SearchFilterPipeTimesheet } from './pipes/search-filter-timesheet.pipe';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    InitialsPipe,
    WallComponent,
    NewFooterComponent,
    SearchFilterPipe,
    DynamicFormComponent,
    DynamicFieldComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    DateComponent,
    ButtonComponent,
    // TitleComponent,
    // TextareaComponent,
    // ChipComponent,
    // DynamicExpansionPanelComponent,
    AutocompleteComponent,
    ChipfullwidthComponent,
    LabelComponent,
    AttachmentComponent,
    AttachmentDialogComponent,
    DragDirective,
    // FilterMenuComponent,
    PhonenumberComponent,
    MaintenanceComponent,
    // PrivacyConsentComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    AttachmentDragDropComponent,
    AttachmentComponent1,
    ChangePasswordComponent,
    ValidatorComponent,
    ForgotPasswordComponent,
    SnackbarComponent,
    ConfirmDialogComponent,
    ConfirmDialogComponent,
    UnauthComponent,
    SearchFilterPipeTimesheet
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    NgbModule
  ],
  exports: [HeaderComponent,
    FooterComponent,
    InitialsPipe,
    WallComponent,
    NewFooterComponent,
    SearchFilterPipe,
    DynamicFormComponent,
    DynamicFieldComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    DateComponent,
    RadiobuttonComponent,
    // TitleComponent,
    // TextareaComponent,
    // ChipComponent,
    // DynamicExpansionPanelComponent,
    AutocompleteComponent,
    ChipfullwidthComponent,
    LabelComponent,
    AttachmentComponent,
    AttachmentDialogComponent,
    DragDirective,
    // FilterMenuComponent,
    PhonenumberComponent,
    MaintenanceComponent,
    // PrivacyConsentComponent,
    CheckboxComponent,
    AttachmentDragDropComponent,
    AttachmentComponent1,
    ValidatorComponent,
    SnackbarComponent,
    ConfirmDialogComponent,
    UnauthComponent,
    SearchFilterPipeTimesheet
  ]
})
export class SharedModule { }
