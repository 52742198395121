<div class="container-fluid signup_form" [ngStyle]="{'height': height}">
    <div class="row inner_signup_form">
        <div class="col-md-12">
            <div class="privacy_content">
                <div class="row">
                    <h1>We&rsquo;ll be back soon!</h1>
                    <div>
                    <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back online shortly!</p>
                    <p>&mdash; Infosys Partner Hub</p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12" style="padding:0;">
                        <div class="field-bg">
                            <label style="position: relative;float: left; text-transform: capitalize;">Copyright {{currentYear}} Infosys Limited</label>
                            <br>
                        </div>
                    </div>

                </div> -->


            </div>
        </div>


    </div>
</div>

