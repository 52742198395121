export const ValidationMessages: { [key: string]: string } = {
    'required': 'Field is mandatory',
    'email': 'Email must be a valid email address',
    'NoPassswordMatch': 'Password doesn\'t match',
    'hasNumber': 'Must have at least 1 number!',
    'hasCapitalCase': 'Must contain at least 1 in Capital Case!',
    'hasSmallCase': 'Must contain at least 1 Letter in Small Case!',
    'minlength': 'Must be at least 10 characters!',
    'hasSpecialCharacters': 'Must be at least 1 special characters!',
    'maxlength': 'Must be at least 50 characters!',
    'percentageValue': 'please enter in percentage format',
    'hasDecimalpoint': 'please enter upto 2 decimals only',
    'correctdate': 'Please enter correct date',
    'requiredIst': 'Please select skills from list',
    'currentemployer': 'Please enter company name from list'
}

export const ValidationMessagePerField: { [key: string]: { [key: string]: string } } = {
    'photo': {
        'required': 'Please upload your Photograph.'
    },
    'FirstName': {
        'required': 'Please enter First Name.'
    },
    'LastName': {
        'required': 'Please enter Last Name.'
    },
    'transport': {
        'required': 'Please enter your requirement for Transport.'
    },
    'FatherName': {
        'required': 'Please enter Father\'s/Guardian Name.'
    },
    'MobileNo': {
        'required': 'Please enter Mobile Number.'
    },
    'EMobileNo': {
        'required': 'Please enter Emergency Contact Number.',       
        'EmobileMatch': 'Emergency Contact Number is same.'
    },
    'EmailId': {
        'required': 'Please enter Email.'
    },
    'voterid': {
        'required': 'Please enter Voter Id.'
    },
    'driving': {
        'required': 'Please enter Driving License Id.'
    },
    'panid': {
        'required': 'Please enter Pan Id.'
    },
    'nation': {
        'required': 'Please enter Citizenship.'
    },
    'genderdetail': {
        'required': 'Please choose Gender.'
    },
    'dateofbirth': {
        'required': 'Please enter Date of Birth.'
    },
    'passportdetail': {
        'required': 'Please choose Yes/No.'
    },
    'passno': {
        'required': 'Please enter Passport number.',
        'maxlength' : 'Please enter maximum 15 characters.'
    },
    'validity': {
        'required': 'Please enter Passport validity.'
    },
    'selection': {
        'required': 'Please choose atleast one Address Proof.'
    },
    'resume': {
        'required': 'Please upload your Resume.'
    },
    'countrycode': {
        'required': 'Please enter Country Code.'
    },
    'mobiledigits': {
        'required': 'Please enter Mobile Number.'
    },
    'street': {
        'required': 'Please enter Street Name/Area.'
    },
    'building': {
        'required': 'Please enter Address details.'
    },
    'zipcode': {
        'required': 'Please enter PIN Code.',
        'minlength': 'Please enter minimum 6 characters.'
    },
    'city': {
        'required': 'Please enter City.'
    },
    'state': {
        'required': 'Please enter State.'
    },
    'country': {
        'required': 'Please enter Country.'
    },
    'citizenship': {
        'required': 'Please enter Citizenship(s).'
    },
    'passport': {
        'required': 'Please select Yes or No.'
    },
    'gender': {
        'required': 'Please select Gender.'
    },
    'annualgross': {
        'required': 'Please enter Annual Gross Compensation.',
        'maxLength': 'Please enter Correct Annual Gross Compensation.'
    },
    'annualgrossC': {
        'required': 'Please enter Annual Gross Compensation.'
    },
    'fixedsalary': {
        'required': 'Please enter Fixed Salary.'
    },
    'variablepay': {
        'required': 'Please enter Variable Pay.'
    },
    'companyname': {
        'required': 'Please enter Company.'
    },
    'designation': {
        'required': 'Please enter Designation.'
    },
    'date': {
        'required': 'Please enter Date.',
        'matDatepickerMin': 'Please enter correct date.'
    },
    'toDate': {
        'required': 'Please enter To (MMM-YYYY).',
        'matDatepickerMin': 'Please enter correct date.'
    },
    'fromDate': {
        'required': 'Please enter From (MMM-YYYY).',
        'matDatepickerMin': 'Please enter correct date.'
    },
    'nextdate': {
        'required': 'Please enter date.',
    },
    'duration': {
        'required': 'Please enter Duration.'
    },
    'reason': {
        'required': 'Please enter Reason for Separation.'
    },
    'reasongaps': {
        'required': 'Please enter Reason.'
    },
    'expletter': {
        'required': 'Please select Yes or No.'
    },
    'qualification': {
        'required': 'Please enter Qualification details.'
    },
    'board': {
        'required': 'Please enter Board.'
    },
    'board_university': {
        'required': 'Please enter Board/University.'
    },
    'university': {
        'required': 'Please enter University.'
    },
    'school': {
        'required': 'Please enter School.'
    },
    'school_college': {
        'required': 'Please enter School/College.'
    },
    'branch': {
        'required': 'Please enter Branch.'
    },
    'marks': {
        'required': 'Please enter Marks.'
    },
    'percentage': {
        'required': 'Please enter Percentage.',
        'max':'Please enter valid Percentage.'
    },
    'college': {
        'required': 'Please enter College/Institute.'
    },
    'exemployee': {
        'required': 'Please enter Employee Number.'
    },
    'gapyear': {
        'required': 'Please enter Gaps in education.'
    },
    'domain': {
        'required': 'Please enter Education type.'
    },
    'correctdatevalue': {
        'correctdate': 'Please enter correct date.'
    },
    'degree': {
        'required': 'Please enter correct Degree.'
    },
    'interviewLocation': {
        'required': 'Please select correct Interview Location.'
    },
    'negotiate': {
        'required': 'Please enter Notice Period.'
    },
    'notice': {
        'required': 'Please enter Notice Period (Days).',
        'maxLength': 'Please enter Correct Annual Gross Compensation.'
    },
    'joblocation': {
        'required': 'Please enter Preferred Job Location.'
    },
    'firstnameX': {
        'required': 'Please enter First Name.'
    },
    'lastnameX': {
        'required': 'Please enter Last Name.'
    },
    'emailX': {
        'required': 'Please enter Email.',
        'email': 'Please enter a valid Email in abc@xyz.com format.'
    },
    'passwordX': {
        'required': 'Please enter Password.',
        'minlength': 'Please enter password with atleast 8 characters.',
        'hasSmallCase': 'Please enter password with atleast one lowercase letter.',
        'hasCapitalCase': 'Please enter password with atleast one uppercase letter.',
        'hasNumber': 'Please enter password with atleast one number.',
        'hasSpecialCharacters': 'Please enter password with atleast one special character.',
        'maxlength': 'Please enter password within 50 characters.',
    },
    'cpasswordX': {
        'required': 'Please enter Password.',
        'NoPassswordMatch': 'Passwords doesn\'t match.'
    },
    'termsnconditionX': {
        'required': 'Please accept terms and conditions.'
    },
    'firstname': {
        'required': 'Please enter First Name.'
    },
    'lastname': {
        'required': 'Please enter Last Name.'
    },
    'mobileno': {
        'required': 'Please enter Mobile Number.'
    },
    // 'skills':{
    //     'required':'Please enter atleast one skill'
    // },
    'fatherspouse': {
        'required': 'Please enter Father/Spouse name.'
    },
    'mobile': {
        'required': 'Please enter Mobile Number.'
    },
    'emergencyno': {
        'required': 'Please enter Emergency Contact Number.'
    },
    'email': {
        'required': 'Please enter Email.'
    },
    'altemail': {
        'required': 'Please enter Alternate Email.'
    },
    'pan': {
        'required': 'Please enter Pan Number.'
    },
    'aadhaar': {
        'required': 'Please enter Aadhaar Number.'
    },
    'dob': {
        'required': 'Please enter Date of Birth.'
    },
    'passportno': {
        'required': 'Please enter Passport Number.'
    },
    'validupto': {
        'required': 'Please enter Validity of Passport.'
    },
    'houseno': {
        'required': 'Please enter House No./Name/Apartment Name.'
    },
    'apartment': {
        'required': 'Please enter Apartment Name.'
    },
    'area': {
        'required': 'Please enter Area Name.'
    },
    'landmark': {
        'required': 'Please enter Landmark.'
    },
    'refname1': {
        'required': 'Please enter Name.'
    },
    'organisation1': {
        'required': 'Please enter Organisation.'
    },
    'declarationaccepted': {
        'required': 'Please accept terms and conditions.'
    },
    'contact1': {
        'required': 'Please enter Contact Details.'
    },
    'desgination1': {
        'required': 'Please enter Designation.'
    },
    'relation1': {
        'required': 'Please select Relationship.'
    },
    'mail1': {
        'required': 'Please enter Email.'
    },
    'name2': {
        'required': 'Please enter Name.'
    },
    'organisation2': {
        'required': 'Please enter Organisation.'
    },
    'contact2': {
        'required': 'Please enter Contact Details.'
    },
    'designation2': {
        'required': 'Please enter Designation.'
    },
    'email2': {
        'required': 'Please enter Email.'
    },
    'name3': {
        'required': 'Please enter Name.'
    },
    'organisation3': {
        'required': 'Please enter Organisation.'
    },
    'contact3': {
        'required': 'Please enter Contact Details.'
    },
    'designation3': {
        'required': 'Please enter Designation.'
    },
    'mail3': {
        'required': 'Please enter Email.'
    },
    'fixedsalpa': {
        'required': 'Please enter current Fixed Salary per annum.'
    },
    'fixedsalpm': {
        'required': 'Please enter current Fixed Salary per month.'
    },
    'totalgross': {
        'required': 'Please enter total Gross Salary.'
    },
    'totalfvp': {
        'required': 'Please enter Total Salary with Fixed and Variable pay.'
    },
    'takehome': {
        'required': 'Please enter take Home Salary.'
    },
    'apartment2': {
        'required': 'Please enter Apartment Name.'
    },
    'area2': {
        'required': 'Please enter Area Name.'
    },
    'street2': {
        'required': 'Please enter Street Name/Area.'
    },
    'house2': {
        'required': 'Please enter House No./Name/Apartment Name.'
    },
    'emailid': {
        'required': 'Please enter Email.'
    },
    'skills': {
        'required': 'Please enter atleast one Skill.'
    },
    'landmark2': {
        'required': 'Please enter Landmark.'
    },
    'jobtype': {
        'required': 'Please select Job Type.'
    },
    'specialization': {
        'required': 'Please enter Specialization.'
   
    },
    'obligationDetail' : {
        'required' : 'Please enter Obligation Detail.'
    },
    'otherBenefits' : {
        'required' : 'Please enter Other Benefits.' 
    },
    'panproof': {
        'required': 'Please upload your PAN.'
    },
    'drivingproof': {
        'required': 'Please upload your Driving License.'
    },
    'voterproof': {
        'required': 'Please upload your Voter Id.'
    },
    'aadharproof': {
        'required': 'Please upload your Aadhar Id.'
    },
    'passportproof': {
        'required': 'Please upload your Passport.'
    },
    'EduTypeValue': {
        'required': 'Please choose an Education Type .'
    },
    'jobProfession':{
        'required': 'Please choose profession.'
    },
    'jobGenericSkills':{
        'required':'Please enter generic skills.'
    },
    'jobPreferredSkills':{
        'required':'Please enter preferred skills.'
    },
    'jobDomain':{
        'required':'Please choose domain.'
    },
    'jobExperience':{
        'required':'Please add job experience.'
    },
    'jobEducation':{
        'required':'Please choose education.'
    },
    'jobLocation':{
        'required':'Please choose location.'
    },
    'uniqueCode':{
        'required':'Please enter correct unique code.'
    }


}