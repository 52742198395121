import { Component, OnInit, Input } from '@angular/core';
// import { TelemetryServices } from 'src/app/core/services/telemetry.service';
import { AbstractControl } from '../../../../../node_modules/@angular/forms';
import { ValidationMessages, ValidationMessagePerField } from '../../services/validationMessages';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {
  data = { // Required
    "type": "validator component", //Required. Impression type (list, detail, view, edit, workflow, search)
    "subtype": "", //Optional. Additional subtype. "Paginate", "Scroll"
    "pageid": "validatorid", //Required.  Unique page id
    "itype": "", // type of interaction - SWIPE, SCRUB (fast forward using page thumbnails) or AUTO
    "stageto": "" // game level, stage of page id to which the navigation was done
  };
  
  @Input() control: AbstractControl;
  @Input() configuredMessage: { [key: string]: string } = null;
  @Input() nameType: string;
  // private _telemetryServices : TelemetryServices
  constructor() { }

  ngOnInit() {
    // //(this.control);
    
  }

  showError(): boolean {
    // //(this.control);

    return this.control && ((this.control.touched || this.control.dirty) && !!this.control.errors);
    
    
  }

  getError() : string{
  //  //(this.control);
    if(this.control.errors){
      const firstError = Object.keys(this.control.errors)[0];
      //(firstError,'error');
      
      return this.getMessage(firstError);
    }
    return '';
  }

  getMessage(type: string){
    if(this.control.errors){
      if(!! this.configuredMessage && !!this.configuredMessage[type]){
        return this.configuredMessage[type];
      }   
      if(!this.nameType){   
      return ValidationMessages[type];
      }else{
        return ValidationMessagePerField[this.nameType][type];
      } 
    }
  }
  ngOnDestroy() {
    this.data.subtype = 'validator component';
    this.data.itype = 'AUTO';
    this.data.stageto = 'validators for validation of user inputs and for showing appropriate error messages';
    if (this.data) {
      // this._telemetryServices.telemetryImpression(this.data);
    }
   
  }

}
