import { KeycloakService } from 'keycloak-angular';
// import { environment } from '../environments/environment';ss

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    
    
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                // console.log(window.location.href,"spp");
                let browserWindow: any = window || {};
                let browserWindowEnv = browserWindow['__env'] || {};
                console.log(browserWindowEnv,"spp");
                    await keycloak.init({
                        config: {
                            url: browserWindowEnv.keycloakUrl,
                            realm: browserWindowEnv.keycloakRealm,
                            clientId: browserWindowEnv.keycloakClientId,
                            // credentials: {
                            //   secret: 'secret_key'
                            // }

                        },
                        loadUserProfileAtStartUp: false,
                        initOptions: {
                            onLoad: 'check-sso',
                            checkLoginIframe: false
                        },
                        bearerExcludedUrls: []
                    });
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    };
}