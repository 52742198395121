<div class="container-fluid bg-imageBody">
    <div class="infosyslogo">
        <div class="container-fluid">
            <div id="content-wrapper" class="content-wrapper">
                <!-- <div class="infosyslogo">
    
    
                </div> -->
                <div class="panel customPanelClass">
                    <div class="panel-body customPanelBody">
                        <div class="col-md-12">
                            <h1 class="customProjectName">Partner Hub</h1>
                        </div>
                        <div class="col-md-12">
                            <p class="customLoginName">Change Password</p>
                        </div>

                        <form [formGroup]="ResetPasswordForm" class="ResetPasswordForm marT24">
                            <div class="row">
                                <div class="col-md-12 marB8">
                                    <mat-form-field class="custom-form-field">
                                        <mat-label>Old Password</mat-label>
                                        <input matInput [type]="!isOPwdToggle ? 'text' : 'password'" formControlName="oldPassword" name="oldPassword" required>
                                            <button type="button" mat-icon-button matSuffix (click)="isOPwdToggle = !isOPwdToggle;" [attr.aria-pressed]="isOPwdToggle">
                                                <mat-icon>{{isOPwdToggle ? 'visibility_off' : 'visibility'}}</mat-icon>
                                              </button>
                                    </mat-form-field>
                                    <div style="font-size: small;margin-top:-16px">
                                        <app-validator name="ValPassw"
                                            [control]="ResetPasswordForm.get('oldPassword')"></app-validator>
                                    </div>
                                </div>
                                <div class="col-md-12 marB8">
                                    <mat-form-field class="custom-form-field">
                                        <mat-label>New Password</mat-label>
                                        <input matInput [type]="!isPwdToggle ? 'text' : 'password'"
                                            formControlName="passwordX" name="passwordX" required>
                                            <button type="button" mat-icon-button matSuffix (click)="isPwdToggle = !isPwdToggle;" [attr.aria-pressed]="isPwdToggle">
                                                <mat-icon>{{isPwdToggle ? 'visibility_off' : 'visibility'}}</mat-icon>
                                              </button>
                                    </mat-form-field>
                                    <div style="font-size: small;margin-top:-16px">
                                        <app-validator name="ValPassw"
                                            [control]="ResetPasswordForm.get('passwordX')"></app-validator>
                                    </div>
                                </div>
                                <div class="col-md-12 marB16">
                                    <mat-form-field class="custom-form-field">
                                        <mat-label>Confirm Password</mat-label>
                                        <input matInput [type]="!isCPwdToggle ? 'text' : 'password'" name="cpasswordX"
                                            formControlName="cpasswordX" type="password" required>
                                        <button type="button" mat-icon-button matSuffix (click)="isCPwdToggle = !isCPwdToggle;" [attr.aria-pressed]="isCPwdToggle">
                                         <mat-icon>{{isCPwdToggle ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    
                                    <div style="font-size: small;margin-top:-16px">
                                        <app-validator name="valConfir"
                                             [control]="ResetPasswordForm.get('cpasswordX')"></app-validator>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div>
                                        <button type="button" id="btnSubmit"
                                            class="resetButtonCss DSA_wb_miniSecondary-btn"
                                            (click)="submit()">
                                            <span class="buttonTextReset">
                                                Confirm
                                            </span>
                                        </button>
                                        <br>
                                        <button type="button" id="btnSubmit"
                                            class="customButtonCss DSA_wb_miniSecondary-btn marT16"
                                            (click)="onCancel()">
                                            <span class="buttonText">
                                                Cancel
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>