<div class="container-fluid bg-imageBody">
    <div class="infosyslogo">
        <div class="container-fluid">
            <div id="content-wrapper" class="content-wrapper">
                <!-- <div class="infosyslogo">
    
    
                </div> -->
                <div class="panel customPanelClass">
                    <div class="panel-body customPanelBody">
                        <div class="col-md-12">
                            <h1 class="customProjectName">Partner Hub</h1>
                        </div>
                        <div class="col-md-12">
                            <p class="customLoginName">Forgot Password</p>
                        </div>

                        <form [formGroup]="ForgotPasswordForm" style="margin-top: 48px;" class="ForgotPasswordForm">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="custom-form-field">
                                        <mat-label>Please enter your email</mat-label>
                                        <input matInput
                                            formControlName="emailX" name="emailId">
                                    </mat-form-field>
                                    <!-- <div *ngIf="ForgotPasswordForm.controls.emailX.errors.required">
                                      <p style="color: red;">Email address is required</p>
                                    </div> -->
                                    <mat-error style="font-size: small;margin-top:-16px"
                                        *ngIf="ForgotPasswordForm?.get('emailX')?.touched && ForgotPasswordForm?.get('emailX')?.hasError('required')">
                                        Email address is required
                                    </mat-error>
                                </div>
                                <div class="col-md-12 marT32">
                                    <div>
                                        <button type="button" id="btnSubmit"
                                            class="resetButtonCss DSA_wb_miniSecondary-btn"
                                            (click)="handleButtonClick()">
                                            <span class="buttonTextReset">
                                                Confirm
                                            </span>
                                        </button>
                                        <br>
                                        <button type="button" id="btnSubmit"
                                            class="customButtonCss DSA_wb_miniSecondary-btn marT16"
                                           (click)="onCancel()">
                                            <span class="buttonText">
                                                Cancel
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>