export const environment = {
  type: 'dev',
  production: true,
  // production: false,s
  telemetryServiceHost: "",
  KeyClockServiceUrl: "",
  TELEMETRY_SERVICE_HOST : "",
  TELEMETRY_SERVICE_ENDPOINT : "/v1/telemetry",
  keyClock : "",
  HIPModuleId: '4214',
  HIPChoosePlanChildId: '6442',
  EFHIPSummaryId: '4120',
  mvValidationId: '6451',
  addPriorStaySelfChildId: '3095',
  javaServiceBaseUrl: '',
  previousyearChildId: 3181,
  previousyearChildIdEFHIP: 5220,
  idlesessiontime: "900",
  fileuploadReg: "6400",
  fileuploadDereg: "6425",
  sectionJSON: 'https://itgatewaytst.infosys.com/alumni/ui/api/generictemplate/GetSection?intModuleId={0}&strQueryValue={1}',
  gatewayBaseUrl: 'https://isgatewaytst',
  ehipPlaDetailsUrl: '',
  landingPageId:"1",
  invoiceId:"2",
  fileUrl: '',
  changePassword:"16",
  jobDescId:'12',
  confirmOfferDetails:"15",
  candidateProfileId:"8",
  candidateProfileECMSId:"13",
  disclaimerConsentId:"16",
  vendorProfileId:"14",
  intranetGatewayBaseUrl: 'https://isgatewaytst',
  internetGatewayBaseUrl: 'https://itgatewaytst',
  gatewayBaseUrlwithoutInfydigital: 'https://itgatewaytst.infosys.com',
  internetUrl:"https://itgatewaytst.infosys.com",
  jobApplyPopupId:"17",
  partnerHubUrlInTap:"",
  ecmsGatewayUrl: "https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/",
  partnerHubFrameWorkServices:"",
  partnerHubServices:"",
  ecmsPartnerHubGateWayUrl:"",
  benfitsUrl: 'https://itgatewaytst.infosys.com/infydigital/benefits/api/Benefits/GetBenefitDetails',
  popupModuleId:'10422',
  timeSheetURL:"",
  timeSheetServiceUrl:"",
  loansmoduleid:'6381',
  onboardModuleid:44,
};
