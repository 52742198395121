import { Component, OnInit,Inject } from '@angular/core';
import {EnvService} from '../../../../app/env.service'

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  tncAppVersionData: any;
  tncText: any;
  height:any;
  bgUrl:any;
  date = new Date();
  currentYear:any;
  constructor(private envService: EnvService) { }

  ngOnInit() {
    // this.height = window.innerHeight+'px';    
    this.bgUrl = this.envService.bloburl +  "/careersite/Images/bg/pyramid.png";
    document.documentElement.style.setProperty('--dynamic-url', "url("+this.bgUrl+") 100%");
    this.currentYear=this.date.getFullYear();
  }
  

}
