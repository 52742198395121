import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Modules
import { CoreModule } from './core/core.module';
import { AuthGuardApp } from './core/guards/auth.guard';
import { AuthInterceptor } from './core/interceptors/api.interceptor';
import { EnvServiceProvider } from './env.service.provider';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { KeycloakAngularModule, KeycloakService, KeycloakConfig } from 'keycloak-angular';
import * as jwt_decode from 'jwt-decode';
import { initializer } from './core/constants/app-init';
// import { environment } from 'src/environments/environment.prod';

const keycloakService = new KeycloakService();

// if (environment.production) {
//   enableProdMode();
// }


// import { SearchFilterComponent } from './modules/vendor/components/search-filter/search-filter.component';
// import { FilterListComponent } from  './modules/vendor/components/filter-list/filter-list.component'
@NgModule({
  declarations: [
    AppComponent,
    // SearchFilterComponent,
    // FilterListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    KeycloakAngularModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps:[KeycloakService],
      multi:true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule  {
  // implements DoBootstrap
  loggedInUser: any;
  keycloakConfig: KeycloakConfig;

  constructor(private keycloakConfigService: KeycloakService,) {
  }

  browserWindow: any = window || {};
  browserWindowEnv = this.browserWindow['__env'] || {};

  // ngDoBootstrap(appRef: ApplicationRef) {
  //   console.log(this.browserWindowEnv, "env new");
  //   this.keycloakConfig={
  //       url: this.browserWindowEnv.keycloakUrl,
  //       realm: this.browserWindowEnv.keycloakRealm,
  //       clientId: this.browserWindowEnv.keycloakClientId,
  //     }
  //     keycloakService
  //   .init({
  //     config: this.keycloakConfig,
  //     loadUserProfileAtStartUp: false,
  //     initOptions: {
  //       onLoad: 'check-sso',
  //     checkLoginIframe: false
  //     },
  //     enableBearerInterceptor: true,
  //     bearerPrefix: 'Bearer'
  //   }).then(() => {
  //     console.log("app comp bootstrap");
      
  //     appRef.bootstrap(AppComponent);       
  //     keycloakService.getToken().then((token) => {
  //       console.log("token", token);
  //       if(token){
  //         let jwt = jwt_decode(token);
  //         localStorage.setItem("access_token", token);
  //         this.loggedInUser = {        
  //           username: jwt.email,
  //           email: jwt.email,
  //           firstName: jwt.given_name,
  //           lastName: jwt.family_name,  
  //           middleName: jwt.middle_name,        
  //           emailVerified: jwt.email_verified,
  //           attributes:{}
  //         }
  //         localStorage.setItem("vendorCode", jwt.vendorCode);
  //         // console.log("vendorCode", jwt.vendorCode);
  //         localStorage.setItem("loggedInUser", JSON.stringify(this.loggedInUser));
  //         localStorage.setItem("email", jwt.email);
  //         localStorage.setItem("appBotName", "")
  //       }
  //       /********Key Cloak ********/
  //       setInterval(function () {
          
  //         keycloakService.updateToken(180).then((tokenChecker1) => {
            
  //            if (tokenChecker1) {
  //             keycloakService.getToken().then((token) => {
  //                var jwttoken1 = jwt_decode(token);
  //                localStorage.setItem("access_token", token);
  //                this.loggedInUser = {
  //                  username: jwttoken1.email,
  //                  email: jwttoken1.email,
  //                  firstName: jwttoken1.given_name,
  //                  lastName: jwttoken1.family_name,
  //                  emailVerified: jwttoken1.email_verified,
  //                  attributes: {}
  //                }
  //                localStorage.setItem("vendorCode", JSON.stringify(jwttoken1.vendorCode));
  //                console.log("vendorCode", jwttoken1.vendorCode);
                 
  //                this.loggedUserDetails = this.loggedInUser;
  //                localStorage.setItem("loggedInUser", JSON.stringify(this.loggedInUser));
  //                localStorage.setItem("authenticated", JSON.stringify(true));
  //              })
  //            } else {
  //              //  //('TOKEN NOT REFRESHED!')
  //            }
     
  //          });
     
     
  //        }, 1440000)

  //         /********Key Cloak Ends********/  
  //     })
      

  //   })

  //   .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
  // }



  }
