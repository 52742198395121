import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class EnvironmentLoaderService {
  constructor(private http: HttpClient) { }

  async getEnvData() {
    let envUrl: any = "../../../assets/environments/";
    if (environment.production === true) {
      envUrl += 'environment.json';
    }
    else {
      envUrl += 'environment-dev.json';
    }
    return await this.http.get(envUrl).toPromise()
  }
}
