import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(employeeName: string): string {
    if(!employeeName) {
      return ''
    }
    const namearray = employeeName.trim().split(" ");

    let initial = '';
    let namearrayLength = namearray.length;

    // for (let i = 0; i < namearrayLength; i++) {

      if (namearrayLength <= 1) {
        initial = initial + namearray[0].charAt(0).toUpperCase() + namearray[0].charAt(1).toUpperCase();
        return initial;
      }
      else {
        initial = initial + namearray[0].charAt(0).toUpperCase() + namearray[namearrayLength-1].charAt(0).toUpperCase();
        return initial;
      }


    // }
  }
}
