<!-- <p>new-footer works!</p> -->

<footer
  class="DSA_footer fixed-bottom">
  <div class="container-fluid row">
    <div  class="col-md-6 col-sm-12 col-xs-12" style="position:relative;padding-left: 34px;">
      Copyright © {{currentYear}} Infosys Limited
    </div>
    <div class="marR16 col-md-6 col-sm-12 col-xs-12">
      <a>
        <span  style="position:absolute;top: 0;
          right:30px;cursor: pointer!important;">
          Terms &amp; Conditions
      </span>
      </a>
    </div>
    
  </div>
</footer>
