import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PaymentComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(); // Close the dialog when the close button is clicked
  }

}
