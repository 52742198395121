import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.scss']
})
export class NewFooterComponent implements OnInit {
  date = new Date();
  currentYear:any;
  constructor() { }

  ngOnInit(): void {
    this.currentYear=this.date.getFullYear();
  }

}
