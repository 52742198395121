import { Component, OnInit, ViewChild} from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
  styleUrls: ['./unauth.component.scss']
})
export class UnauthComponent implements OnInit {
  unAuthFlag: any;
  constructor(private route: ActivatedRoute) { }

  @ViewChild('headerRef') headerRef: HeaderComponent;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params,"params");
      this.unAuthFlag = params['flag'];
      console.log(this.unAuthFlag,"params");
  });
  }

  ngAfterViewInit() {
      this.headerRef.hideTabs = true;
      
  }
  

}
