import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import * as jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentLoaderService } from './environment-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  path = 'api/v1/keycloak/PartnerHub';
  entityId = localStorage.getItem("companyHiringTypeId");
  selectedRole = localStorage.getItem("selectedRole");
  appName = localStorage.getItem("appName");

  
  baseUrlPartnerHubServices: string='';
  baseUrlPartnerHubFrameWorkServices: string='';
  timesheetBaseUrl: string='';

  private _roleDetails = new BehaviorSubject({});
  _currentDetails = this._roleDetails.asObservable();

  private refreshSubject = new BehaviorSubject<void>(null);
  refresh$ = this.refreshSubject.asObservable();

  private accessSubject = new BehaviorSubject<boolean>(null);
  access$ = this.accessSubject.asObservable();

  constructor(private http: HttpClient, private keycloakService: KeycloakService, private envLoaderSerive: EnvironmentLoaderService, private snackBar: MatSnackBar) {

    this.envLoaderSerive.getEnvData().then((envjson) => {
      environment.partnerHubUrlInTap = envjson['InTapPartnerHubUrl'];
      environment.partnerHubServices = envjson['PartnerHubServices'];
      environment.ecmsPartnerHubGateWayUrl = envjson['ECMSPartnerHubGateWayUrl'];
      environment.KeyClockServiceUrl = envjson['KeyClockServiceUrl'];
      environment.timeSheetServiceUrl=envjson['TimeSheetServiceUrl'];
      environment.partnerHubFrameWorkServices=envjson['PartnerHubFrameWorkServices'];
      this.timesheetBaseUrl=environment.timeSheetServiceUrl;
      this.baseUrlPartnerHubFrameWorkServices=environment.partnerHubFrameWorkServices;
    });
    console.log(environment.timeSheetServiceUrl, "env", environment);
  }

  changedRoleDetails(roleDetails) {
    this._roleDetails.next(roleDetails);
  }

  triggerRefresh() {
    this.refreshSubject.next();
  }

  accessCheck(accessValue) {
    this.accessSubject.next(accessValue);
  }

  getUserDetails() {
    this.baseUrlPartnerHubServices=environment.partnerHubServices;
    return this.http.get<any>(this.baseUrlPartnerHubServices + "api/userDetails");
  }

  //change or update password //keycloak
  resetPassword(resetObj) {
    let accessToken = localStorage.getItem("access_token");
    let jwttoken1 = jwt_decode(accessToken);
    let kcUserId = jwttoken1.sub;
    let baseurl=environment.KeyClockServiceUrl;
    return this.http.post<any>(baseurl  + kcUserId + "/password/isfupdate", resetObj)
  }

  //keycloak
  logoutSession() {
    let idToken = this.keycloakService.getKeycloakInstance().idToken;
    let baseurl=environment.keyClock;
    window.location.replace(baseurl+"/realms/" + "PartnerHub" + "/protocol/openid-connect/logout?post_logout_redirect_uri=" + window.location.origin + "&id_token_hint=" + idToken);
  }

  getDisclaimerConsent(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    this.appName=localStorage.getItem("appName");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "fetchdpoconsent?appName=" + this.appName + "&entityId=" + this.entityId, httpOptions);
  }

  SaveConsent(url, consentObj) {
    if (url) {
      return this.http.post<any>(url, consentObj)
    }
    return this.http.post<any>(this.baseUrlPartnerHubServices + "saveconsent", consentObj)
  }

  dpoCheck(reqId, appName) {
    console.log(this.baseUrlPartnerHubServices, "base", environment);
    this.entityId=localStorage.getItem("companyHiringTypeId");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "dpoCheck?reqId=" + reqId + "&appName=" + appName + "&entityId=" + this.entityId);
  }

  getJobApply(appName) {
    // return this.http.get<any>("/assets/json/jobApplyData.json")
    this.entityId=localStorage.getItem("companyHiringTypeId");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "getJobApplyJson?entityId=" + this.entityId + "&appName=" + appName);
  }

  //keycloak
  forgotPassword(data): Observable<any> {
    let baseurl=environment.KeyClockServiceUrl;
    return this.http.post<any>(baseurl + data.emailX + "/password/resetRL", '')
  }

  openSnackBar(message: any, panelClass: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 2500,
    });
  }
  openSnackBarNew(message: any, panelClass: string,duration:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: duration,
    });
  }

  restrictSpclChar(e){
    var key = document.all ? e.keyCode : e.which;
    return ((key > 64 && key < 91) || (key > 96 && key < 123) || key == 8 || key == 32 || key >= 48 && key <= 57 || key == 45 || key == 46 || key == 44);
  }

  rstrctPasteSpclChar(e){
    if(e && e.clipboardData && e.clipboardData.getData('text')){
        if(!(e.clipboardData.getData('text').match('^[a-zA-Z0-9 .,-]*$'))){
          return false;
       }
    }  
  }

  dpoCheckForSubcon(roleSelected) {
    this.entityId=localStorage.getItem("companyHiringTypeId");
    return this.http.get<any>(this.baseUrlPartnerHubServices + "subConDpoCheck?roleName=" + roleSelected + "&entityId=" + this.entityId);
  }

  getTimesheetAuth() {
    console.log(this.timesheetBaseUrl, "env timesheet");
    return this.http.get<any>(this.timesheetBaseUrl + "v1/timetrnemptimesheets/TimesheetLoginAuthorization");
  }

  blobURL(fileName) {
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');
    this.baseUrlPartnerHubFrameWorkServices=environment.partnerHubFrameWorkServices;
    return this.http.get<any>(this.baseUrlPartnerHubFrameWorkServices+"azureblob/downloadFile?strQueryValue="+fileName, { headers: headers })
  }

}
