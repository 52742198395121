
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler,  HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    token: any;
    constructor() {
        this.token = localStorage.getItem("access_token");
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<any> {

        
        this.token = localStorage.getItem("access_token");
        const token = this.token;
        if (!!token && !req.url.includes("maintenance-connectinfy.json") && !req.url.includes("botConfigConnectInfy.json") && 
        !req.url.includes(
            "/forgot-password"
            )) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
        }
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body.success) {
                    }
                }
            }),
            catchError((err: any) => {
                return throwError(err);
            }));

    }
}
