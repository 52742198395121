import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.error(err));



// authentication keycloak starts keycloakUrl or https://identitypoc.infosysapps.com/auth

// const browserWindow: any = window || {};
// const browserWindowEnv = browserWindow['__env'] || {};

// // let initOptions = {
// //   url: browserWindowEnv.keycloakUrl,
// //   realm: browserWindowEnv.keycloakRealm,
// //   clientId: browserWindowEnv.keycloakClientId,
// //   // url:'http://10.53.99.196:8080/', 
// //   // realm:'vendor', 
// //   // clientId:'vendor'
// // }
// // console.log(initOptions, "keycloak");
// // let keycloak = new Keycloak(initOptions);
// // console.log(keycloak, "keycloak");

// keycloak.init({ 
//   onLoad: 'login-required'
//  }).then((auth) => {

//   if (!auth) {
//     window.location.reload();
//   } else {
//     console.log("Authenticated");
    
//   }
//   localStorage.setItem("access-token", keycloak.token ? keycloak.token : "");
//   localStorage.setItem("refresh-token", keycloak.refreshToken ? keycloak.refreshToken : "");



//   setInterval(() => {
//     keycloak.updateToken(70).then((refreshed) => {
//       if (refreshed) {
//         console.log(refreshed);

//       }
//     }).catch(() => {
//       console.log("Failed to refresh token.");

//     })

//   }, 1440000);
// }).catch(() => {
//   console.log("Authentication Failed !");

// })
