import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { TelemetryServices } from './core/services/telemetry.service';
import { TelemetryService } from '@infosys-it/angular-telemetry-sdk';
import { EnvironmentLoaderService } from './core/services/environment-loader.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import * as jwt_decode from 'jwt-decode';
import { SharedService } from './core/services/shared.service';
import { VendorService } from './core/services/vendor.service';
import * as Keycloak from 'keycloak-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'partnerhub-ui';
  telemetryendurl: string = environment.TELEMETRY_SERVICE_ENDPOINT
  BATCH_SIZE = 1;
  public telemetryConfig = {
    'pdata': {
      'id': 'angular.partnerHub',
      'ver': '1.0.0',
      'pid': ''
    },
    'uid': '',
    'authtoken': '',
    'env': 'value',
    'channel': 'web',
    'batchsize': this.BATCH_SIZE,
    'host': "",
    'endpoint': this.telemetryendurl,
    'apislug': '',
    // 'sid':this.guid
  };
  maintenanceMode: boolean;
  token1: string;
  name: string;
  unAuthFlag: boolean = true;

  constructor(private service: TelemetryService, private envLoaderSerive: EnvironmentLoaderService, private http: HttpClient, private router: Router, private keycloakservice: KeycloakService, private sharedService: SharedService) {
    // this.telemetryConfig =this.telemtryService.telemetryConfig;
    console.log("help links partnerhub code optimization changes");
  }


  ngOnInit(): void {
    this.keycloakservice.getToken(false).then((res: string) => {
      try {
        this.token1 = res;
        console.log(this.token1, "tokenn");
        if (this.token1) {
          localStorage.setItem("access_token", this.token1);
          var decoded = jwt_decode(this.token1);
          localStorage.setItem("vendorCode", decoded.vendorCode);
          localStorage.setItem("email", decoded.email);
          localStorage.setItem("loggedInUser", decoded.firstName);
          setInterval(function () {
            let keycloak = Keycloak();
            keycloak.updateToken(100).then((refreshed) => {

              if (refreshed) {
                localStorage.setItem("access_token", keycloak.token);
                localStorage.setItem("refresh-token", keycloak.refreshToken);
                var decoded = jwt_decode(this.token1);
                localStorage.setItem("vendorCode", decoded.vendorCode);
                localStorage.setItem("email", decoded.email);
                localStorage.setItem("loggedInUser", decoded.firstName);
                console.debug('Token refreshed' + refreshed);
              } else {
                console.warn('Token not refreshed, valid for '
                  + Math.round(this.keycloakservice.tokenParsed.exp + this.keycloakservice.timeSkew - new Date().getTime() / 1000) + ' seconds');
              }

            });


          }, 1440000)
        }
      }

      catch (error) {
        console.log("User not authenticated");
      }
    });
    this.service.provider = 'SUNBIRD';
    this.envLoaderSerive.getEnvData().then((envjson) => {
      console.log(envjson);
      console.log("--updated");
      environment.TELEMETRY_SERVICE_HOST = envjson['TELEMETRY_SERVICE_HOST'];
      environment.TELEMETRY_SERVICE_ENDPOINT = envjson['TELEMETRY_SERVICE_ENDPOINT'];
      environment.timeSheetURL = envjson['TimeSheetServiceUrl'];
      environment.partnerHubServices = envjson['PartnerHubServices'];
      environment.timeSheetServiceUrl = envjson['TimeSheetServiceUrl'];
      environment.partnerHubFrameWorkServices = envjson['PartnerHubFrameWorkServices'];
      this.telemetryConfig['host'] = envjson['TELEMETRY_SERVICE_HOST'];
      this.telemetryConfig['authtoken'] = localStorage.getItem("access_token");
      this.telemetryConfig['uid'] = localStorage.getItem("email");
      this.service.setProviderImplementation('SUNBIRD', this.telemetryConfig);
      this.service.start(this.telemetryConfig, 'contentId1', '1.0', {});
      this.service.log({ type: 'Info', itype: 'logging', pageid: 'AppComponent', detail: localStorage.getItem('email') + ' has logged in successfully having vendor code ' + localStorage.getItem('vendorCode') })
      console.log(this.telemetryConfig, "tele app", envjson);
      this.loadApp();
    });
  }

  loadApp() {
    if (!window.location.pathname.includes("/forgot-password")) {
      let userEmail: any = this.getUserEmail();
      console.log(userEmail, "email");
      if (userEmail) {
        const emailDomain = userEmail.split('@')[1];
        if (emailDomain && (emailDomain.toLowerCase() === 'ad.infosys.com' || emailDomain.toLowerCase() === 'infosys.com')) {
          let user: any = this.checkUserAccess();
          console.log(user, "user");
          if (!user.subconFlag) {
            this.unAuthFlag = true;
            let navigationExtras: NavigationExtras = {
              queryParams: {
                flag: this.unAuthFlag,
              }
            }
            this.sharedService.accessCheck(this.unAuthFlag);
            this.router.navigate(['/unauth'], navigationExtras);
          } else {
            this.unAuthFlag = false;
            let navigationExtras: NavigationExtras = {
              queryParams: {
                flag: this.unAuthFlag,
              }
            }
            this.sharedService.accessCheck(this.unAuthFlag);
            this.router.navigate(['/app-wall'], navigationExtras);
          }
        } else {
          this.unAuthFlag = false;
          let navigationExtras: NavigationExtras = {
            queryParams: {
              flag: this.unAuthFlag,
            }
          }
          this.sharedService.accessCheck(this.unAuthFlag);
          this.router.navigate(['/app-wall'], navigationExtras);
        }
      }
    }
  }


  checkUserAccess() {
    return new Promise((resolve, reject) => {
      // let timeSheetURL = environment.timeSheetServiceUrl;
      this.sharedService.getTimesheetAuth().subscribe((data) => {
        resolve(data);
      }, error => {
        let info = {
          msg: 'Technical error occured, please try again later',
          isSuccess: false
        }
        this.sharedService.openSnackBar(info, 'success-snack');
        this.unAuthFlag = true;
        let navigationExtras: NavigationExtras = {
          queryParams: {
            flag: this.unAuthFlag,
          }
        }
        this.sharedService.accessCheck(this.unAuthFlag);
        this.router.navigate(['/unauth'], navigationExtras);
      })
    })
  }

  getUserEmail() {
    return new Promise((resolve, reject) => {
      let userEmail = localStorage.getItem('email');
      if (userEmail) {
        resolve(userEmail);
      } else {
        this.sharedService.getUserDetails().subscribe((data) => {
          resolve(data.email);
        });
      }

    })
  }
}