import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { EnvironmentLoaderService } from '../services/environment-loader.service'

@Injectable()
export class AuthGuardApp implements CanActivate {
  constructor(private envLoaderSerive: EnvironmentLoaderService) {

  }

  async canActivate() {
    let envjson = await this.envLoaderSerive.getEnvData();
    console.log(envjson, "getting json value@@@@@");
    // environment.apiUrl = envjson['ApiUrl'];
    // environment.fileUrl = envjson['FileUrl'];
    // environment.type = envjson['Type'];
    // environment.loggedUserDetailsUrl = envjson['LoggedUserDetailsUrl'];
    // environment.botUrl = envjson['BotUrl'];
    // environment.tncUrl = envjson['TNCUrl'];
    // environment.validationUrl = envjson['ValidationUrl'];
    // TELEMETRY_SERVICE_HOST : "",
    // TELEMETRY_SERVICE_ENDPOINT : "",
    // keyClock : "https://infysso-poc.infosysapps.com/auth",
    // HIPModuleId: '4214',
    // HIPChoosePlanChildId: '6442',
    // EFHIPSummaryId: '4120',
    // mvValidationId: '6451',
    // addPriorStaySelfChildId: '3095',
    // javaServiceBaseUrl: 'http://10.68.191.109/',
    // previousyearChildId: 3181,
    // previousyearChildIdEFHIP: 5220,
    // fileuploadReg:"6400",
    // fileuploadDereg:"6425",
    // sectionJSON:'https://itgatewaytst.infosys.com/alumni/ui/api/generictemplate/GetSection?intModuleId={0}&strQueryValue={1}',
    // gatewayBaseUrl: 'https://isgatewaytst',
    // ehipPlaDetailsUrl:'http://10.82.5.132:7755/api/HealthInsurance/GetEFHIPMasterPlanDetails?IsMobile=true',
    // // vendorportal apis
    // landingPageId:"1",
    // invoiceId:"2",
    // fileUrl: 'https://intaptest.infosysapps.com/infosys/intapgenric/file/',
    // changePassword:"16",
    // jobdescId:'2071',
    // confirmOfferDetails:"15",
    // candidateProfileId:"8",
    // candidateProfileECMSId:"13",
    // disclaimerConsentId:"17",
    // vendorProfileId:"14",
    // jobApplyPopupId:"18",
    // inTapPartnerHubUrl:"https://intaptest.infosysapps.com/infosys/intappartnerhub/",
    // ecmsGatewayUrl: "https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/",
    // partnerHubFrameWorkServices:""
    environment.TELEMETRY_SERVICE_ENDPOINT = envjson['TELEMETRY_SERVICE_ENDPOINT'];
    environment.TELEMETRY_SERVICE_HOST=envjson['TELEMETRY_SERVICE_HOST'];
    environment.fileUrl = envjson['FileUrl'];
    environment.partnerHubFrameWorkServices = envjson['PartnerHubFrameWorkServices'];
    environment.partnerHubUrlInTap = envjson['PartnerHubUrlInTap'];
    environment.partnerHubServices=envjson['PartnerHubServices'];
    environment.ecmsPartnerHubGateWayUrl=envjson['ECMSPartnerHubGateWayUrl'];
    environment.timeSheetURL = envjson['TimeSheetServiceUrl'];
    environment.KeyClockServiceUrl = envjson['KeyClockServiceUrl'];
    environment.keyClock = envjson['KeyClock'];
    environment.timeSheetServiceUrl = envjson['TimeSheetServiceUrl'];
    return true;
  }
}