import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { environment } from 'src/environments/environment.prod';
import { EnvironmentLoaderService } from './environment-loader.service';
@Injectable({
  providedIn: 'root',
})
export class VendorService {
  userId = 'a12ec5e1-c470-4044-a282-3e1d5669eb57';
  companyHiringTypeId = localStorage.getItem('companyHiringTypeId');
  selectedRole=localStorage.getItem("selectedRole");
  baseUrlLocalTapServices:string="";
  baseUrlLocalFrameWork:string="";
  baseUrlLocalPartnerHubServices:string="";
  baseUrlECMS:string=environment.ecmsPartnerHubGateWayUrl;
  baseUrlPartnerHubServices:string="";
  baseUrlIntapServices:string="";
  data: any;
  
  private _jobDetails= new BehaviorSubject({});
  _currentDetails=this._jobDetails.asObservable();

  private countSource = new BehaviorSubject<number>(0);
  currentCount = this.countSource.asObservable();

  
  constructor(private http: HttpClient, private snackBar: MatSnackBar, private envLoaderSerive: EnvironmentLoaderService) {

    this.envLoaderSerive.getEnvData().then((envjson) => {
      environment.partnerHubUrlInTap = envjson['InTapPartnerHubUrl'];
      environment.partnerHubServices=envjson['PartnerHubServices'];
      environment.ecmsPartnerHubGateWayUrl=envjson['ECMSPartnerHubGateWayUrl'];
      environment.partnerHubFrameWorkServices = envjson['PartnerHubFrameWorkServices'];
    });
    this.companyHiringTypeId = localStorage.getItem('companyHiringTypeId');
    // this.baseUrlPartnerHubServices=environment?.partnerHubServices;
    // this.baseUrlIntapServices=environment?.inTapPartnerHubUrl;
  }

  changedJobDetails(jobDetails) {
    this._jobDetails.next(jobDetails);
  }

  changeCount(count: number) {
    this.countSource.next(count);
  }

  //8083
  getHeaderEntityTabs(entityId) {
    this.companyHiringTypeId = localStorage.getItem('companyHiringTypeId');
    this.baseUrlPartnerHubServices=environment?.partnerHubServices;
    // return this.http.get<any>('/assets/json/EntityheaderTabs.json'); use this
    // return this.http.get<any>("/assets/json/roleheaderTabs.json")
    // if(!roleId){
    return this.http.get<any>(this.baseUrlPartnerHubServices+"/api/user/entityRolesFeatures?entityId="+this.companyHiringTypeId);
    // }
    // else
    
  }

  //8083
  getHeaderRolesTabs(entityId, roleId) {
    this.companyHiringTypeId = localStorage.getItem('companyHiringTypeId');
    this.baseUrlPartnerHubServices=environment?.partnerHubServices;
    this.selectedRole=localStorage.getItem("selectedRole");
    // return this.http.get<any>('/assets/json/entityRolesFeatures.json')
    return this.http.get<any>(this.baseUrlPartnerHubServices+"api/user/roleFeatures?entityId="+this.companyHiringTypeId + "&roleName="+this.selectedRole)
    // return this.http.get<any>("http://10.246.116.48/8083/"+"api/user/roleFeatures?entityId="+this.companyHiringTypeId + "&roleName="+this.selectedRole)
  }

  //8181
  getJobcards(entityId?, vendorCode?,url?) {
    // this.baseUrlECMS=environment.ecmsPartnerHubGateWayUrl;
    // console.log(localStorage.getItem('companyHiringTypeId'),"entity id");
    
    // return this.http.get<any>(this.baseUrlECMS + 'api/vendorme/getjobcard/' + this.companyHiringTypeId)
    if(url){
      this.companyHiringTypeId = encodeURIComponent(localStorage.getItem('companyHiringTypeId'));
      let jobCardsUrl=url.replace("{0}", this.companyHiringTypeId);
      return this.http.get<any>(jobCardsUrl);
      // return this.http.get<any>(this.baseUrlLocalTapServices+"/api/getVendorJobs?searchText=All&entityId="+this.entityId);
      return this.http.get("/assets/json/jobcardsAPIrespUpdated.json");
    }
     return this.http.get("/assets/json/jobcardsAPIrespUpdated.json");
    // return this.http.get("/assets/json/vendorjobsECMS.json");
    // return this.http.get('/assets/json/jobcardsEcmsAPI.json')
    // return this.http.get<any>("https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/getjobcard/0600000138")
    // return this.http.get<any>(this.baseUrlECMS + 'api/vendorme/getjobcard/' + this.companyHiringTypeId)
  }

  //8181
  getJobdescription(jobDescriptionUrl) {
    // return this.http.get<any>(this.baseUrlECMS + "api/vendorme/getjobdescriptiondet/"+this.companyHiringTypeId + '/' +437146);
    if(jobDescriptionUrl){
      return this.http.get<any>(jobDescriptionUrl);
      // return this.http.get("/assets/json/jobdescription.json");
    }
    return this.http.get('/assets/json/jobdescription.json');
    // return this.http.get('/assets/json/jobdescriptionAPIrespUpdated.json');
    // return this.http.get("/assets/json/jobdescriptionECMS.json");
    //
    // return this.http.get("/assets/json/jobdescriptionEcmsAPI.json");
    // return this.http.get<any>("https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/getjobdescriptiondet/"+this.companyHiringTypeId + '/' +requestNumber);
    // return this.http.get<any>(this.baseUrlECMS + "api/vendorme/getjobdescriptiondet/"+this.companyHiringTypeId + '/' +requestNumber);
  }

  //8083
  getProfileshared(url) {
    // return this.http.get("/assets/json/profileshared.json");
    // return this.http.get('/assets/json/profilesharedAPI.json', profileObj);
    // return this.http.post(
    //   profileObj
    // );
    return this.http.get(environment.partnerHubUrlInTap+'api/getVendorUserProfile');
    // return this.http.get<any>(profileObj);
  }

  getCandidateReqStatus() {
    return this.http.get<any>('/assets/json/complianceCardLeft.json');
  }
  getPendingStatus() {
    // return this.http.get<any>('/assets/json/pending.json');
    // return this.http.get<any>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/listcompliance/${this.companyHiringTypeId}/${vendorCode}/Pending`
    // );
    return this.http.get<any>(
      this.baseUrlECMS + `api/vendorme/listcompliance/${this.companyHiringTypeId}/Pending`
    );
  }
  getProgressStatus() {
    // return this.http.get<any>('/assets/json/inProgress.json');
    // return this.http.get<any>(
    // );
    // return this.http.get<any>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/listcompliance/${this.companyHiringTypeId}/${vendorCode}/Progress`
    // );
    return this.http.get<any>(
      this.baseUrlECMS + `api/vendorme/listcompliance/${this.companyHiringTypeId}/InProgress`
    );
  }
  getClosedStatus() {
    // return this.http.get<any>('/assets/json/closed.json');
    // return this.http.get<any>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/listcompliance/${this.companyHiringTypeId}/${vendorCode}/Closed`
    // );
    return this.http.get<any>(
      this.baseUrlECMS + `api/vendorme/listcompliance/${this.companyHiringTypeId}/Closed`
    );
  }
  getBGVDocs( requestNo, candidateId) {
    // return this.http.get<any>("/assets/json/BGVDocUploadsNew.json")
    // return this.http.get<any>(`https://isgatewaytst.ad.infosys.com/infydigital/ecmsservice/api/vendorme/getuploadeddocumentsdetails/${requestNo}/${vendorCode}/${candidateId}`)
    // return this.http.get<any>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/getuploadeddocumentsdetails/${this.companyHiringTypeId}/${requestNo}/${vendorCode}/${candidateId}`
    // );
    //  return this.http.get<any>(
    //  );
    return this.http.get<any>(
     this.baseUrlECMS + `api/vendorme/getuploadeddocumentsdetails/${this.companyHiringTypeId}/${requestNo}/${candidateId}`
    );
  }
  getDownloadData(val): Observable<HttpResponse<Blob>> {
    const requestOptions: Object = {
      /* other options here */
      observe: 'response',
      responseType: 'blob',
    };
    // return this.http.get<HttpResponse<Blob>>(
    //   requestOptions
    // );
    // return this.http.get<HttpResponse<Blob>>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsservice/api/vendorme/downloadbgvdoc?docID=${val}`,
    //   requestOptions
    // );
    // return this.http.get<HttpResponse<Blob>>(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/downloadbgvdoc/${this.companyHiringTypeId}/${val}`,
    //   requestOptions
    // );
    return this.http.get<HttpResponse<Blob>>(
      this.baseUrlECMS + `api/vendorme/downloadbgvdoc/${this.companyHiringTypeId}/${val}`,
      requestOptions
    );
  }
  getCompliance(reqNo) {
    // return this.http.get("/assets/json/complianceECMS.json");
    // return this.http.get("/assets/json/ecmscomplianceAPI.json")
    // return this.http.get(
    // return this.http.get(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/compliancereqdets/' +
    //     this.companyHiringTypeId +
    //     '/' +
    //     reqNo
    // );
    return this.http.get(
      this.baseUrlECMS + 'api/vendorme/compliancereqdets/' +
        this.companyHiringTypeId +
        '/' +
        reqNo
    );
    // return this.http.get("https://isgatewaytst.ad.infosys.com/infydigital/ecmsservices/api/vendorme/compliancereqdets/423316");
    // return this.http.get(this.baseUrlECMS + 'api/vendorme/compliancereqdets/' + this.companyHiringTypeId + '/' + reqNo);
  }

  getComments(requestNumber, candidateId) {
  this.baseUrlECMS=environment.ecmsPartnerHubGateWayUrl;
    // return this.http.get<any>("https://isgatewaytst.ad.infosys.com/infydigital/ecmsservice/api/vendorme/commentdetails/"+requestNumber +'/' +vendorCode +'/'+candidateId)
    // return this.http.get<any>('/assets/json/getcommentsECMS.json');
    // return this.http.get<any>(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/commentdetails/' +
    //     this.companyHiringTypeId +
    //     '/' +
    //     requestNumber +
    //     '/' +
    //     vendorCode +
    //     '/' +
    //     candidateId
    // );
    return this.http.get<any>(this.baseUrlECMS + 'api/vendorme/commentdetails/' + this.companyHiringTypeId +'/' + requestNumber + '/' + candidateId );
  }
  getCommentsSave(commentsObj) {
    this.baseUrlECMS=environment.ecmsPartnerHubGateWayUrl;
    // return this.http.post(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/savecomment',
    //   commentsObj
    // );
    // return this.http.post(
    //   commentsObj
    // );
    return this.http.post(this.baseUrlECMS + 'api/vendorme/savecomment', commentsObj);
  }
  getAcceptOffergenModel(vcode, SAPcompanyCode) {
    this.baseUrlECMS=environment.ecmsPartnerHubGateWayUrl;
    // return this.http.get<any>(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/acceptoffergenmodel/' +
    //     this.companyHiringTypeId +
    //     '/' +
    //     vcode +
    //     '/' +
    //     SAPcompanyCode
    // );
    return this.http.get<any>(
      this.baseUrlECMS + 'api/vendorme/acceptoffergenmodel/' + this.companyHiringTypeId );
    // return this.http.get<any>('/assets/json/acceptOfferAPI.json')
  }
  getDownloadFile(url: string): Observable<Blob> {
    //   return this.http.get<any>("",{observe:Response,response:'blob'})
    return this.http.get(url, {
      responseType: 'blob',
    });
    // return this.http.get(`${environment.partnerHubFrameWorkServices}azureblob/downloadFile?strQueryValue=${name}`, {
    //   responseType: 'blob',
    // });
  }
  getConfirmOfferTable(url) {
    // return this.http.get<any>(
    // );
    // return this.http.get<any>(`/assets/json/confirmOfferTable.json`);
    // return this.http.get<any>(`https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/listconfirmoffer/${this.companyHiringTypeId}/${vendorCode}`)
    let urlValue = url.replace('{0}',this.companyHiringTypeId);
     return this.http.get<any>(urlValue);
  }

  rejectOffer(reqNo, candId) {
    // return this.http.post(
    //   {}
    // );
    // return this.http.post(
    //   `https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/rejectoffer/${reqNo}/${candId}`,
    //   {}
    // );
    return this.http.post(
      this.baseUrlECMS + `api/vendorme/rejectoffer/${reqNo}/${candId}`,
      {}
    );
  }

  upload(FormData) {
    // return this.http.post(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/bgvdocumentupload',
    //   FormData
    // );
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(
      this.baseUrlECMS + 'api/vendorme/bgvdocumentupload',
      FormData, requestOptions
    );
  }
  getConfirmSubmitOffer(submitObj) {
    // return this.http.post(
    //   'http://192.168.43.107:8085/api/vendorme/submitacceptoffer',
    //   submitObj
    // );
    // return this.http.post(
    //   'https://isgatewaytst.ad.infosys.com/infydigital/ecmsspringservice/api/vendorme/submitacceptoffer',
    //   submitObj
    // );
    return this.http.post<any>(this.baseUrlECMS + 'api/vendorme/submitacceptoffer', submitObj);
  }
  openSnackBar(message: any, panelClass: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 2500,
    });
  }
  setData(data: any) {
    this.data = data;
  }
  getData() {
    return this.data;
  }
  getSectionView(){
    return this.http.get<any>("/assets/json/subconFilter.json");
  }
  async getInboxTableData(url){
    return await this.http.get<any>(url).toPromise();
  }
}
