import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '../../../../../node_modules/@angular/forms';

// @Component({
//   selector: 'app-validator',
//   templateUrl: './validator.component.html',
//   styleUrls: ['./validator.component.scss']
// })
export class CustomValidators {

  // @Input() control: AbstractControl;
  // @Input() configuredMessage: { [key: string]: string } = null;
  constructor() { }
 
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
  
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
  
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('passwordX').value; // get password from our password form control
    const confirmPassword: string = control.get('cpasswordX').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('cpasswordX').setErrors({ NoPassswordMatch: true });
    }
  }

  static percentageValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
  
      // test the value of the control against the regexp supplied
      const numvalue = Number(control.value);
  
      // if true, return no error (no error), else return error passed in the second parameter
      return numvalue >=0 && numvalue <=100 ? null : error;
    };
  }

  static mobileMatchValidator(control: AbstractControl) {
    const mobile: string = control.get('MobileNo').value; // get password from our password form control
    const emobile: string = control.get('EMobileNo').value; // get password from our confirmPassword form control
    // compare is the password math
    if (mobile === emobile) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('EMobileNo').setErrors({ EmobileMatch: true });
    }
  }  

  static mobileCodeValidator(mobileCode:string, mobiledigits:string):ValidatorFn{
    return (control: AbstractControl): {[key:string]:any} | null =>{   
      const form= control as FormGroup
      var valid;
      const mobileCode1 = form.get(mobileCode).value; 
      const mobiledigits1= form.get(mobiledigits).value;
      
      var regex= /^[1-9][0-9]{9}$/; 
      const err={noCodeMatch:true}
      valid= regex.test(mobiledigits1);
      //if countrycode is 91
      if(mobileCode1 == "91"){
        if(!valid){
          form.get(mobiledigits).setErrors(err);
          return err;
        }

      }
      else{ 
        const codeError = form.get(mobiledigits).hasError('noCodeMatch');
        if(codeError){
        delete form.get(mobiledigits).errors['noCodeMatch'];
        form.get(mobiledigits).updateValueAndValidity();
        }
        
      }    
    };
  } 
}
